/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /****************
        **  GDPR modal window
        *****************/
//         $('#GDPRModal').modal('show');

        /****************
        **  SVG swap out with PNG on incompatible browsers
        *****************/
        if (!Modernizr.svg) {
          $('img[src*="svg"]').attr('src', function() {
            return $(this).attr('src').replace('.svg', '.png');
          });
        }

        (function(){
            socializer( '.socializer' );
        }());

        /****************
        **  PRIMARY NAV
        *****************/

        /* add individual button class to Login buttons
        ------------------ */
        $('#menu-login-buttons li a,#menu-login-buttons-mobile li a').each(function(){
          $(this).addClass($(this).attr('title'));
          $(this).removeAttr('title');
        });
        $('#corporateNavMobile').on('shown.bs.collapse', function () {
          $('body').addClass('primary-nav-engaged');
        });
        $('#corporateNavMobile').on('show.bs.collapse', function () {
          $('body').addClass('primary-nav-engaging');
        });
        $('#corporateNavMobile').on('hide.bs.collapse', function () {
          $('body').removeClass('primary-nav-engaged primary-nav-engaging');
        });


        /* hide/show interactivty of Blog Menu dropdowns & Search, plus mobile menus for Category and Corporate
        ------------------ */
        $('#corporateNavMobile').on('show.bs.collapse', function () {
          $('[aria-controls=corporateNavMobile').addClass('expanded');
          $('#CategoriesMobile').collapse('hide');
          $('#SpecialSections').collapse('hide');
          $('#SearchSite').collapse('hide');
        });
        $('#corporateNavMobile').on('hide.bs.collapse', function () {
          $('[aria-controls=corporateNavMobile').removeClass('expanded');
        });
        $('#CategoriesMobile').on('show.bs.collapse', function () {
          $('[aria-controls=CategoriesMobile').addClass('expanded');
          $('#corporateNavMobile').collapse('hide');
          $('#SpecialSections').collapse('hide');
          $('#SearchSite').collapse('hide');
        });
        $('#CategoriesMobile').on('hide.bs.collapse', function () {
          $('[aria-controls=CategoriesMobile').removeClass('expanded');
        });
        $('#SpecialSections').on('show.bs.collapse', function () {
          $('[aria-controls=SpecialSections').addClass('expanded');
          $('#corporateNavMobile').collapse('hide');
          $('#SearchSite').collapse('hide');
          $('#CategoriesMobile').collapse('hide');
        });
        $('#SpecialSections').on('hide.bs.collapse', function () {
          $('[aria-controls=SpecialSections').removeClass('expanded');
        });
        $('#SearchSite').on('show.bs.collapse', function () {
          $('[aria-controls=SearchSite').addClass('expanded');
          $('#corporateNavMobile').collapse('hide');
          $('#SpecialSections').collapse('hide');
          $('#CategoriesMobile').collapse('hide');
        });
        $('#SearchSite').on('hide.bs.collapse', function () {
          $('[aria-controls=SearchSite').removeClass('expanded');
        });

        /* ESC to close all expanded menus
        ------------------ */
        $(document).keyup(function(e) {
          if (e.keyCode === 27) { // escape key maps to keycode `27`
            $('#corporateNavMobile,#CategoriesMobile,#SpecialSections,#SearchSite').collapse('hide');
          }
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // archive page
    'archive': {
      init: function() {
        // JavaScript to be fired on the home page
//         $('#menu-primary-navigation > .menu-item:first-child').removeClass('active');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Article page
    'single': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
